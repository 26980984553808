/* eslint-disable class-methods-use-this */
import googleMaps from 'google-maps'
import uuid from 'uuid/v4'

googleMaps.KEY = process.env.GOOGLE_MAPS_KEY

export default class {
  constructor() {
    this.clearQueue()
  }

  instance() {
    const self = this
    const instanceId = this.uuid

    return {
      async locate(address, options) {
        const startTime = new Date()
        const result = await new Promise((resolve, reject) =>
          self.getLatLng(
            { instanceId, address, options, startTime },
            { resolve, reject }
          )
        )
        return { ...result, originalAddress: address }
      }
    }
  }

  getLatLng(params, promiseBindings, retries = 0) {
    const { maxRetries, retryFn } = params.options || { maxRetries: 0 }
    const { instanceId, address, startTime } = params
    googleMaps.load(google => {
      const geocoder = new google.maps.Geocoder()
      geocoder.geocode({ address }, (results, status) => {
        try {
          if (instanceId !== this.uuid) {
            promiseBindings.reject({ error: 'Request expired' })
          } else if (status === google.maps.GeocoderStatus.OK) {
            const elapsed = new Date().getTime() - startTime.getTime()
            const matchedAddress = address
            const formattedAddress = results[0].formatted_address
            promiseBindings.resolve({
              location: results[0],
              formattedAddress,
              matchedAddress,
              retries,
              elapsed
            })
          } else if (status === google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
            setTimeout(
              this.getLatLng.bind(this, params, promiseBindings, retries),
              200
            )
          } else if (
            status === google.maps.GeocoderStatus.ZERO_RESULTS &&
            maxRetries >= retries
          ) {
            const newAddress = retryFn ? retryFn(address) : address
            const newParams = { ...params, address: newAddress }
            setTimeout(
              this.getLatLng.bind(
                this,
                newParams,
                promiseBindings,
                retries + 1
              ),
              200
            )
          } else if (maxRetries < retries) {
            promiseBindings.reject({
              error: 'Max retries limit reached'
            })
          } else {
            promiseBindings.reject({
              error: `google maps API error: ${status}`
            })
          }
        } catch (error) {
          promiseBindings.reject({ error, ...params, retries })
        }
      })
    })
  }

  geojsonToPolygon(geojson) {
    const { type } = geojson

    let paths = geojson.coordinates
    let polygonPaths

    return new Promise(resolve => {
      googleMaps.load(google => {
        if (type === 'Polygon') {
          polygonPaths = paths[0].map(
            coordinates =>
              new google.maps.LatLng(coordinates[1], coordinates[0])
          )
        } else if (type === 'MultiPolygon') {
          polygonPaths = paths.map(path =>
            path[0].map(
              coordinates =>
                new google.maps.LatLng(coordinates[1], coordinates[0])
            )
          )
        } else if (type === 'GeometryCollection') {
          paths = geojson.geometries
          polygonPaths = paths.map(path =>
            path.coordinates[0].map(
              coordinates =>
                new google.maps.LatLng(coordinates[1], coordinates[0])
            )
          )
        }

        resolve(
          new google.maps.Polygon({
            paths: polygonPaths
          })
        )
      })
    })
  }

  async locate(address, options) {
    const startTime = new Date()
    const result = await new Promise((resolve, reject) =>
      this.getLatLng(
        { instanceId: this.uuid, address, options, startTime },
        { resolve, reject }
      )
    )
    return { ...result, originalAddress: address }
  }

  clearQueue() {
    this.uuid = uuid()
  }
}
